<template>
  <ModalLayout
    v-model="isOpen"
    content-class="ProjectGroupDialog"
    :padding="0"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :persistent="saving"
    :max-width="canEdit ? 957 : 520"
    @click:close="isOpen = false"
  >
    <template #title>
      {{ projectGroupId ? $t('project.EditGroup') : $t('project.CreateGroup') }}
    </template>
    <v-sheet class="ProjectGroupDialog__card">
      <form
        v-if="!projectGroupId || projectGroup != null"
        ref="jestCard"
        key="form"
        class="ProjectGroupDialog__form"
        @submit.prevent="validateAndSubmit()"
      >
        <label key="name">
          <span class="d-inline-block mb-2">{{ $t('project.NameAsterisk') }}</span>
          <AppTextField
            v-model="form.name"
            :disabled="!canEdit"
            outlined
            dense
            :placeholder="$t('project.GroupName')"
            type="text"
            name="name"
            maxlength="128"
            :autofocus="!projectGroupId"
            :error-messages="getErrors('name')"
          />
        </label>
        <label key="parentID">
          <span class="d-inline-block mb-2">{{ $t('project.Location') }}</span>
          <ProjectGroupSelect
            v-model="form.parentID"
            :disabled="!canEdit"
            name="parentID"
            :error-messages="getErrors('parentID')"
          />
        </label>
        <label key="description">
          <span class="d-inline-block mb-2">{{ $t('project.Description') }}</span>
          <AppTextarea
            key="description"
            v-model="form.description"
            :readonly="!canEdit"
            outlined
            dense
            :placeholder="$t('project.Description')"
            auto-grow
            rows="1"
            :max-height="168"
            type="text"
            name="description"
            :error-messages="getErrors('description')"
          />
        </label>
      </form>
      <ProjectGroupMultiSelectList
        v-if="(!projectGroupId || projectGroup != null) && canEdit"
        key="children"
        class="ProjectGroupDialog__children"
        :selected-groups.sync="form.childrenBatch"
        :selected-projects.sync="form.projectsBatch"
      />
    </v-sheet>

    <template #footer="{ close }">
      <v-spacer />
      <v-btn
        v-if="canEdit"
        :disabled="saving"
        :loading="saving"
        depressed
        color="primary"
        @click="validateAndSubmit()"
      >
        {{ $t('project.Save') }}
      </v-btn>
      <v-btn
        :disabled="saving"
        outlined
        color="primary"
        class="ml-2"
        @click="close"
      >
        {{ $t('project.Cancel') }}
      </v-btn>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'
import * as R from 'ramda'
import { required, maxLength } from 'vuelidate/lib/validators'
import i18n from '../i18n'

import { reportError } from '../helpers'
import ProjectGroup from '../store/orm/projectGroup'

import ProjectGroupMultiSelectList from './ProjectGroupMultiSelectList'
import ProjectGroupSelect from './ProjectGroupSelect'

const validationErrors = {
  'name-required': i18n.t('project.validationErrors.NameRequired'),
  'name-maxLength': i18n.t('project.validationErrors.NameMaxLength'),
}

const formValidation = {
  name: { required, maxLength: maxLength(128) },
  description: { /* required */ },
  parentID: {},
}

export default {
  name: 'ProjectGroupDialog',

  components: {
    ModalLayout,
    ProjectGroupMultiSelectList,
    ProjectGroupSelect,
  },

  inheritAttrs: false,

  props: {
    dialogInstance: { type: Object, required: true },
    projectGroupId: { type: String, default: null },
  },

  data() {
    return {
      form: null,

      saving: false,
    }
  },

  validations: {
    form: formValidation,
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    projectGroup() {
      const { projectGroupId } = this
      return projectGroupId && ProjectGroup.find(projectGroupId)
    },

    currentUser() { return this.$store.getters['user/current'] },

    canEdit() { return this.currentUser?.isAdmin },

    firstError() {
      const fields = Object.keys(formValidation)
      for (const field of fields) {
        const errors = this.getErrors(field)
        if (errors.length) return errors[0]
      }
      return null
    },
  },

  watch: {
    isOpen: {
      handler(isOpen) {
        this.$store.commit('defaultLayout/setPushAjaxLoaderTop', isOpen)
        if (isOpen) this.initForm()
      },
      immediate: true,
    },

    projectGroup: {
      handler: 'initForm',
      immediate: true,
    },
  },

  methods: {
    initForm() {
      const { projectGroup } = this
      this.form = projectGroup
        ? R.pipe(
          R.pick(['name', 'parentID', 'description']),
          R.mergeDeepLeft({
            childrenBatch: projectGroup.getChildrenQ().all().map(g => g.id),
            projectsBatch: projectGroup.getProjectsQ().all().map(p => p.id),
          }),
          R.clone,
        )(projectGroup)
        : this.getDefaultForm()
      this.$v.$reset()
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('project.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async validateAndSubmit() {
      const { form, projectGroupId } = this

      // 1. handle validation
      this.$v.$touch() // highlight errors
      if (this.$v.$error) {
        this.$store.commit('$snackbar/setMessage', {
          message: this.firstError || this.$t('project.fixErrorsM'),
        })
        return
      }

      // 2. determine action and payload
      const isNew = projectGroupId == null
      const action = isNew ? '$create' : '$update'
      const projectGroup = R.pipe(
        R.clone,
        R.when(() => !isNew, R.assoc('id', projectGroupId)),
        R.evolve({
          childrenBatch: ids => ids.map(id => ({ id })),
          projectsBatch: ids => ids.map(id => ({ id })),
        }),
      )(form)

      // 3. save group
      this.saving = true
      try {
        await ProjectGroup.dispatch(action, { projectGroup })
        await this.onSuccess()
      } catch (e) {
        await reportError(e)
      } finally {
        this.saving = false
      }
    },

    async onSuccess() {
      this.isOpen = false
    },

    getDefaultForm() {
      return {
        name: '',
        parentID: null,
        description: '',
        childrenBatch: [],
        projectsBatch: [],
      }
    },
  },
}
</script>

<style lang="sass">
.ProjectGroupDialog
  overflow: hidden

  &__card
    display: flex
    flex-wrap: nowrap
    width: 100%
    height: 400px
    max-height: 100%
    overflow: hidden

  &__form
    flex: 1 0 400px
    padding: 27px 40px
    max-height: min(550px, 80vh)
    overflow: auto
    background: #FAFAFC

    fieldset
      background: white

  &__children
    flex: 0 1 349px
    overflow: hidden auto
</style>
