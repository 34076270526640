<template>
  <v-list-item
    class="ProjectGroupMultiSelectListProject"
    :class="{
      'ProjectGroupMultiSelectListProject--selected': selected,
    }"
    :ripple="{ class: 'app-ripple' }"
    v-on="$listeners"
  >
    <div
      class="text-truncate"
      v-text="project.name"
    />
    <v-icon
      class="ml-auto"
      v-text="selected ? 'mdi-minus' : 'mdi-plus'"
    />
  </v-list-item>
</template>

<script>
export default {
  name: 'ProjectGroupMultiSelectListProject',

  props: {
    project: { type: Object, required: true },
    selected: { type: Boolean, default: false },
    level: {
      type: Number,
      default: 0,
      validator: v => !Number.isNaN(v) && v >= 0,
    },
  },
}
</script>

<style lang="sass">
.ProjectGroupMultiSelectListProject
  padding-left: 24px !important
  padding-right: 20px !important

  font-size: 14px
  line-height: 20px
  letter-spacing: 0.005em

  &--selected
    background: #EDF0FA !important
</style>
