<template>
  <div
    class="ProjectGroupMultiSelectListGroup"
    :class="{
      'ProjectGroupMultiSelectListGroup--selected': selected,
    }"
  >
    <v-list-item
      class="ProjectGroupMultiSelectListGroup__group-item"
      :ripple="{ class: 'app-ripple' }"
      @click="$emit('toggle-group', { groupId: group.id })"
    >
      <v-icon
        class="mr-1"
        v-text="group.getIcon(true)"
      />
      <div
        class="text-truncate"
        v-text="group.name"
      />
      <v-icon
        class="ml-auto"
        v-text="selected ? 'mdi-minus' : 'mdi-plus'"
      />
    </v-list-item>

    <template v-for="item in items">
      <ProjectGroupMultiSelectListGroup
        v-if="item.isGroup"
        :key="item.id"
        :group="item.group"
        :selected-projects="selectedProjects"
        :selected-groups="selectedGroups"
        :level="level + 1"
        @toggle-project="$emit('toggle-project', $event)"
        @toggle-group="$emit('toggle-group', $event)"
      />
      <ProjectGroupMultiSelectListProject
        v-else-if="item.isProject"
        :key="item.id"
        :project="item.project"
        :selected="selectedProjects.includes(item.id)"
        :level="level + 1"
        @click="$emit('toggle-project', { projectId: item.id })"
      />
    </template>
  </div>
</template>

<script>
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

import ProjectGroupMultiSelectListProject from './ProjectGroupMultiSelectListProject'

export default {
  name: 'ProjectGroupMultiSelectListGroup',

  components: {
    ProjectGroupMultiSelectListProject,
  },

  props: {
    group: { type: Object, required: true },
    selectedGroups: { type: Array, default: () => [] },
    selectedProjects: { type: Array, default: () => [] },
    level: {
      type: Number,
      default: 0,
      validator: v => !Number.isNaN(v) && v >= 0,
    },
  },

  computed: {
    items() {
      return this.group
        .getSortedDescendants()
        .map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
          }
        })
    },

    selected() {
      return this.selectedGroups.includes(this.group.id)
    },
  },
}
</script>

<style lang="sass">
.ProjectGroupMultiSelectListGroup

  &__group-item
    padding-left: 24px !important
    padding-right: 20px !important

    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em

  &--selected > &__group-item
    background: #EDF0FA !important
</style>
