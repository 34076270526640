<template>
  <v-list class="ProjectGroupMultiSelectList">
    <div
      key="header"
      class="ProjectGroupMultiSelectList__header"
    >
      <strong class="ProjectGroupMultiSelectList__title">{{ $t('project.AddProjectOrGroupM') }}</strong>
    </div>
    <template v-for="item in items">
      <ProjectGroupMultiSelectListGroup
        v-if="item.isGroup"
        :key="item.id"
        :group="item.group"
        :selected-groups="selectedGroups"
        :selected-projects="selectedProjects"
        @toggle-project="toggleProject($event.projectId)"
        @toggle-group="toggleGroup($event.groupId)"
      />
      <ProjectGroupMultiSelectListProject
        v-else-if="item.isProject"
        :key="item.id"
        :project="item.project"
        :selected="selectedProjects.includes(item.id)"
        @click="toggleProject(item.id)"
      />
    </template>
  </v-list>
</template>

<script>
import Project from '../store/orm/project'
import ProjectGroup from '../store/orm/projectGroup'

import ProjectGroupMultiSelectListGroup from './ProjectGroupMultiSelectListGroup'
import ProjectGroupMultiSelectListProject from './ProjectGroupMultiSelectListProject'

export default {
  name: 'ProjectGroupMultiSelectList',

  components: {
    ProjectGroupMultiSelectListGroup,
    ProjectGroupMultiSelectListProject,
  },

  props: {
    rootGroup: { type: Object, default: null },
    selectedGroups: { type: Array, default: () => [] }, // .sync
    selectedProjects: { type: Array, default: () => [] }, // .sync
  },

  computed: {
    items() {
      const rootGroup = this.rootGroup || ProjectGroup.getRootGroupQ().first() || null
      return rootGroup && rootGroup
        .getSortedDescendants()
        .map((projectOrGroup) => {
          const isProject = projectOrGroup instanceof Project
          const isGroup = projectOrGroup instanceof ProjectGroup
          if (!isProject && !isGroup) throw new Error('TypeError')
          return {
            id: projectOrGroup.id,
            name: projectOrGroup.name,
            isProject,
            isGroup,
            project: isProject ? projectOrGroup : null,
            group: isGroup ? projectOrGroup : null,
          }
        })
    },
  },

  methods: {
    toggleProject(projectId) {
      const newSelection = this.selectedProjects.includes(projectId)
        ? this.selectedProjects.filter(id => id !== projectId)
        : [...this.selectedProjects, projectId]
      this.$emit('update:selectedProjects', newSelection)
    },
    toggleGroup(groupId) {
      const newSelection = this.selectedGroups.includes(groupId)
        ? this.selectedGroups.filter(id => id !== groupId)
        : [...this.selectedGroups, groupId]
      this.$emit('update:selectedGroups', newSelection)
    },
  },
}
</script>

<style lang="sass">
.ProjectGroupMultiSelectList
  padding: 0 !important
  border-left: 1px solid #E6E6F2 !important

  &__header
    padding: 19px 24px
    position: sticky
    top: 0
    background: white
    z-index: 2

  &__title
    font-weight: 500
    font-size: 16px
    line-height: 24px
    letter-spacing: 0.005em
</style>
